import http from "../http-common";

class ManageService {
  /************* start  */
  getList(data) {
    return http.post(`/api/listall.php`, data);
  }
  getAllList(data) {
    return http.post(`/api/listall.php`, data);
  }
  getAttendanceList(data) {
    return http.post(`/api/memberlist.php`, data);
  }
  getGradeListAll(data) {
    return http.post(`/api/grade_list.php`, data);
  }
  getDaysList() {
    //날짜 가져오기
    return http.post(`/api/attendlist.php`);
  }
  setAttendChech(data) {
    //출결 체크
    return http.post(`/api/attendsetting.php`, data);
  }
  reportWrite(data) {
    //보고서 작성
    return http.post(`/api/reportWrite.php`, data);
  }
  getteacher(data) {
    //선생님 리스트
    return http.post(`/api/teacherlist.php`, data);
  }
  register(data) {
    // 셀원 등록
    return http.post(`/api/register.php`, data);
  }
  memberDelete(form) {
    //멤버 탈퇴
    return http.post(`/api/member_delete.php`, form);
  }
  getenrolled(data) {
    //반 제적
    return http.post(`/api/enrolled.php`, data);
  }

  getenrolllist(data) {
    //반 출결
    return http.post(`/api/enrolledlist.php`, data);
  }
  gettotalattend(data) {
    //전체 출결
    return http.post(`/api/attendalllist.php`, data);
  }

  photoUpload(form) {
    //사진업로드
    return http.post(`/api/uploadphoto.php`, form);
  }

  getboardDashboard(data) {
    //대시보드 공지사항
    return http.post(`/api/board_notice.php`, data);
  }

  getboardboardall(form) {
    //공지사항
    return http.post(`/api/board_all.php`, form);
  }

  writerBoardData(form) {
    //공지사항 등록
    return http.post(`/api/board_writer.php`, form);
  }

  deleteBoardData(form) {
    //공지사항 삭제
    return http.post(`/api/board_delete.php`, form);
  }

  getclassreport(form) {
    //셀보고서현황
    return http.post(`/api/class_reports.php`, form);
  }

  getclassstaffreport(form) {
    //스텝용 셀보고서현황
    return http.post(`/api/classs_staff.php`, form);
  }
  

  getweeklyreport(form) {
    //셀보고서현황
    return http.post(`/api/weekly_reports.php`, form);
  }

  weeklyreportWrite(data) {
    //셀보고서 피드백 등록
    return http.post(`/api/weeklyreportWrite.php`, data);
  }

  setGoOut(data) {
    //회원탈퇴
    return http.post(`/api/memberGoOut.php`, data);
  }

  getAbsentAll(data) {
    //장결자 리스트
    return http.post(`/api/getAbsentList.php`, data);
  }

  setReadCheck(data) {
    //읽음 표시
    return http.post(`/api/setReadCheck.php`, data);
  }
  personreports(data) {
    //셀원별보고서 - 셀원 자료 가져오기
    return http.post(`/api/personreports.php`, data);
  }
  searchstudent(data) {
    //셀원별보고서 - 셀원 리스트 찾기
    return http.post(`/api/searchstudent.php`, data);
  }
  studentassign(data) {
    //셀편성
    return http.post(`/api/studentassign.php`, data);
  }
  getCellReaderList(data) {
    //리더 블러오기
    return http.post(`/api/getcellreaderList.php`, data);
  }

  getCellStaffList(data) {
    //스텝 블러오기
    return http.post(`/api/getcellstaffdropList.php`, data);
  }

  getCellMemberList(data) {
    //동의자 전체 리스트 불러오기
    return http.post(`/api/getmemberlistall.php`, data);
  }
  
  getLeaderMemberList(data) {
    //동의자 전체 리스트 불러오기
    return http.post(`/api/getmemberlist.php`, data);
  }

  getCellReaderAllList(data) {
    //리더 블러오기
    return http.post(`/api/getcellreaderallList.php`, data);
  }

  getCellMemberAllList(data) {
    //전체 리더 블러오기
    return http.post(`/api/getcellmemberallList.php`, data);
  }
  
  getLeaderMemberAllList(data) {
    //전체 명단 블러오기
    return http.post(`/api/getmemberalllist.php`, data);
  }
  
  
  AssignMemberSetting(data) {
    //맴버 등록 
    return http.post(`/api/assignmembersetting.php`, data);
  }
  
  AssignReaderSetting(data) {
    // 리더 설정
    return http.post(`/api/assignreadersetting.php`, data);
  }  
  
  AssignManagerSetting(data) {
    // 매니저, 교역자 설정
    return http.post(`/api/assignmanagersetting.php`, data);
  }  

  getCellStaffAllList(data) {
    //스텝 리스트 블러오기
    return http.post(`/api/getcellstaffList.php`, data);
  }

  getStaffMemberList(data) {
    //부서 리더 전체 리스트 불러오기
  return http.post(`/api/getleadermemberlist.php`, data);
  }

  AssignStaffSet(data) {
    //스탭을 지정함
    return http.post(`/api/assignsetstaffsetting.php`, data);
  }

  AssignStaff(data) {
    // 스텝 지정
    return http.post(`/api/assignstaffsetting.php`, data);
  }  

  setFcmToken(data) {
    // 스텝 지정
    return http.post(`/api/setfcmtoken.php`, data);
  }  
  
  getUndoReport(data) {
    // 보고서 미작성자 리스트
    return http.post(`/api/getUndoReport.php`, data);
  }  

  unwritealimtalk(data) {
    // 보고서 미작성, 알림톡 발송 24.9.28
    return http.post(`/api/unwritealimtalk.php`, data);
  } 
  






  
  /** 주보등록 */

  deleteNewsBoardData(form) {
    //주보 삭제
    return http.post(`/api/newsboard_delete.php`, form);
  }

  writerNewsBoardData(form) {
    //주보 등록
    return http.post(`/api/newsboard_writer.php`, form);
  }

  getNewsboardall(form) {
    //주보 리스트
    return http.post(`/api/newsboard_all.php`, form);
  }

  setReadPayCheck(data) {
    //읽음 표시
    return http.post(`/api/setReadPayCheck.php`, data);
  }
  /************* end  */
  insert(data) {
    return http.post(`/manage/insert/`, data);
  }
  getDeptList(data) {
    return http.post(`/manage/dept/list/`, data);
  }
  getExcelDownload(data) {
    return http.post(`/manage/excel/list/`, data);
  }
  getPageList(params) {
    return http.get(`/manage/listpage/`, params);
  }

  sendToPLMsg(data) {
    return http.post(`/manage/sendpl/`, data);
  }

  getExcelDownload_dept(data) {
    return http.post(`/manage/excel/list_dept/`, data);
  }
}

export default new ManageService();
